/** @jsx jsx */
import { jsx } from '@emotion/core';
import Fade from "@material-ui/core/Fade";
import React from "react";
import Menu from "../Menu/Menu";
import {Link} from "react-router-dom";
import logo from './logo.png'
export default function Header() {
    return <React.Fragment>
        <Fade
            in={true}
            style={{transitionDelay: '500ms'}}
        >
            <Link to={"/"}>
                <div className="App-logo" css={{marginBottom: 20}}>
                    <img src={logo} alt="logo" css={{maxWidth: 720, width: '80%', height: 'auto'}} align="center"/>
                </div>
            </Link>
        </Fade>
        <Menu/>
    </React.Fragment>
}