/** @jsx jsx */
import {jsx} from '@emotion/core';
import React from 'react';
import {colors} from './theme';
import {smallDevice, largeDevice} from './utils';
import { ReactComponent as StarSvg } from './star.svg';
import { ReactComponent as LikeSvg } from './like.svg';
import { ReactComponent as HeartSvg } from './heart.svg';

export const Header = ({currentView, modalProps, onAddLike}) => {
    const {student_name: studentName , like_count: likeCount,heart_count: heartCount,star_count: starCount,classroom, id} = currentView;
    const {onClose} = modalProps;
    return (
        <div
            css={{
                alignItems: 'center',
                backgroundColor: '#ece5da',
                boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',
                color: colors.N80,
                display: 'flex ',
                flex: '0 0 auto',
                height: 54,
                justifyContent: 'space-between',

                [smallDevice]: {
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                [largeDevice]: {
                    paddingLeft: 20,
                    paddingRight: 20,
                },
                userSelect: 'none',
            }}
        >
            <div css={{alignItems: 'center', display: 'flex ', minWidth: 0}}>
                <div css={{fontSize: '0.85em', minWidth: 0}}>
                    <div css={{color: colors.N100, fontWeight: 500}}>{studentName}</div>
                    <div
                        css={{
                            color: colors.N60,
                            marginTop: '0.25em',
                            minWidth: 0,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {classroom ? <span>{classroom.name}</span> : null}
                    </div>
                </div>
            </div>
            <div css={{alignItems: 'center', display: 'flex '}}>
                <Button
                    onClick={() => {
                        onAddLike && onAddLike(id, "star")
                    }}
                >
                      <span
                          css={{
                              backgroundColor: 'white',
                              borderRadius: 8,
                              display: 'inline-block',
                              fontSize: '0.7em',
                              fontWeight: 500,
                              lineHeight: 1,
                              marginRight: -12,
                              marginTop: 8,
                              padding: '1px 4px',
                              position: 'relative',
                          }}
                      >
                        {starCount}
                      </span>
                    <StarSvg css={{width: 24,height: 24}} className={isLiked("star", id, starCount) ? "filled": ''} />
                </Button>
                <Button
                    onClick={() => {
                        onAddLike && onAddLike(id, "heart")
                    }}
                >
                      <span
                          css={{
                              backgroundColor: 'white',
                              borderRadius: 8,
                              display: 'inline-block',
                              fontSize: '0.7em',
                              fontWeight: 500,
                              lineHeight: 1,
                              marginRight: -12,
                              marginTop: 8,
                              padding: '1px 4px',
                              position: 'relative',
                          }}
                      >
                        {heartCount}
                      </span>
                    <HeartSvg css={{width: 24,height: 24}} className={isLiked("heart", id, heartCount) ? "filled": ''} />
                </Button>
                <Button
                    onClick={() => {
                        onAddLike && onAddLike(id, "like")
                    }}
                >
                      <span
                          css={{
                              backgroundColor: 'white',
                              borderRadius: 8,
                              display: 'inline-block',
                              fontSize: '0.7em',
                              fontWeight: 500,
                              lineHeight: 1,
                              marginRight: -12,
                              marginTop: 8,
                              padding: '1px 4px',
                              position: 'relative',
                          }}
                      >
                        {likeCount}
                      </span>
                    <LikeSvg css={{width: 24,height: 24}} className={isLiked("like", id, likeCount) ? "filled": ''} />
                </Button>
                <Button
                    onClick={onClose}
                    css={{
                        borderLeft: `1px solid ${colors.N20}`,
                        paddingLeft: 10,
                        [largeDevice]: {marginRight: -10},
                    }}
                >
                    <Close/>
                </Button>
            </div>
        </div>
    );
};

const Button = ({css, ...props}) => (
    <div
        css={{
            alignItems: 'center',
            color: colors.N60,
            cursor: 'pointer',
            display: 'flex ',
            fontWeight: 300,
            height: 32,
            justifyContent: 'center',
            marginLeft: 10,
            position: 'relative',
            textAlign: 'center',
            minWidth: 32,
            touchAction: 'manipulation',
            '&:hover, &:active': {
                color: colors.N100,
            },

            ...css,
        }}
        role="button"
        {...props}
    />
);

const isLiked = (type, id, count) => {
    if(count <= 0 && localStorage.getItem(`like_${type}_${id}`)) {
        localStorage.removeItem(`like_${type}_${id}`)
    }
    return localStorage.getItem(`like_${type}_${id}`)
}

const Svg = ({size, ...props}) => (
    <svg
        role="presentation"
        viewBox="0 0 24 24"
        css={{
            display: 'inline-block',
            fill: 'currentColor',
            height: size,
            stroke: 'currentColor',
            strokeWidth: 0,
            width: size,
        }}
        {...props}
    />
);


export const Close = ({size = 24, ...props}) => (
    <Svg size={size} {...props}>
        <path
            d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"/>
    </Svg>
);
