/** @jsx jsx */
import {jsx} from "@emotion/core";

import useFetch from 'use-http'
import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';
import Gallery from './Gallery/Gallery';

import {ReactComponent as TitleSvg} from "./creations-title.svg";
import ClassroomDropdown from "../../component/ClassroomDropdown/ClassroomDropdown";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFilledInput-root': {backgroundColor: 'rgba(0, 0, 0, 0)'},
        '& .MuiFilledInput-underline:after': {borderBottom: 'none'},
        '& .MuiFilledInput-underline:before': {borderBottom: '2px solid rgba(0, 0, 0, 0)'},
        '& .MuiFormLabel-root': {fontWeight: 500},
        '& .MuiFormLabel-root.Mui-focused': {color: 'rgb(120, 120, 120)', fontWeight: 500},
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#c5c5c5'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c5c5c5'
        },
        '& .PrivateNotchedOutline-root-256': {borderWidth: '2px'}

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#775c29',
        borderRadius: 'calc(20px + 2vmin)',
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Creations() {
    const classes = useStyles();
    const [classroom, setClassroom] = React.useState("random");
    const [creations, setCreations] = useState([])
    const [isOpenError, setIsOpenError] = useState(false)
    const [fetchCreationsRequest, fetchCreationsResponse] = useFetch(process.env.REACT_APP_API_BASEURL, {cachePolicy: 'no-cache'})

    const handleChange = (event) => {
        let value = event.target.value
        if(value != "") {
            setClassroom(value);
        }

    };

    useEffect(() => {
        fetchCreations().then((creations)=>{
            setCreations(creations)
        })
    }, [classroom]);

    useEffect(() => {
        fetchCreations().then((creations)=>{
            setCreations(creations)
        })
    }, [])

    async function fetchCreations() {
        const fetchedCreations = await fetchCreationsRequest.get(`/creation?classroom-id=` + classroom)
        if (fetchCreationsResponse.ok) {
            fetchedCreations.creations.map((creation) => {
                creation.src = creation.obj_url;
                creation.width = creation.obj_width;
                creation.height = creation.obj_height;
                creation.key = creation.id.toString();
                creation.srcSet = [`${creation.src}.s.jpg 1000w`]
                creation.sizes = ["1000vw"]
            })
            return fetchedCreations.creations
        }

        setIsOpenError(true)

    }

    const onAddLike = () => {
        let executed = false;
        return (id, type) => {
            if (!executed && !localStorage.getItem(`like_${type}_${id}`)) {
                executed = true;
                // do something
                fetch(process.env.REACT_APP_API_BASEURL + `/creation/${id}/like?type=${type}`, {method: 'POST'}).then(
                    () => {
                        localStorage.setItem(`like_${type}_${id}`, true);

                        setCreations(creations.map((creation) => {
                            if (creation.id == id) {
                                creation[type + "_count"]++
                            }

                            return creation
                        }))
                        executed = false;
                    }
                ).catch(()=>{
                    setIsOpenError(true)
                })
            }
        };
    }


    return (
        <React.Fragment>
            <Zoom
                in={true}
                style={{transitionDelay: '500ms'}}
            >
                <Paper className={classes.paper}>
                    <Grid container css={{padding: 'calc(2vmin) calc(0.5vmin)'}}>
                        <Grid item md={6} xs={12} css={{padding: 'calc(2vmin) 0'}}>
                            <div>
                                <TitleSvg css={{minWidth: '100px', width: '30%'}}/>
                            </div>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <form className={classes.root} noValidate autoComplete="off">
                                <ClassroomDropdown randomOptionEnabled={true} disabled={fetchCreationsRequest.loading} variant="outlined" className={classes.formControl} onChange={handleChange} value={classroom}/>
                            </form>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={12} xs={12} css={{padding: '0 8px', textAlign: 'center'}}>
                            <Gallery photos={creations} onAddLike={(id, type) => {
                                onAddLike()(id, type)
                            }}/>
                            {!fetchCreationsRequest.loading && creations && creations.length <=0 && <p>未有作品</p>}
                            {isOpenError && <Alert severity="error">
                                發生錯誤。
                            </Alert>}
                            <Fade
                                in={fetchCreationsRequest.loading}
                                style={{
                                    transitionDelay: fetchCreationsRequest.loading ? '800ms' : '0ms',
                                }}
                                unmountOnExit
                            >
                                <CircularProgress />
                            </Fade>
                        </Grid>
                    </Grid>
                </Paper>
            </Zoom>
        </React.Fragment>
    );
}

export default Creations;
