/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, {Component} from "react";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {ReactComponent as SubmitButtonSvg} from "../../assets/submit-button.svg";
import {ReactComponent as WorksButtonSvg} from "../../assets/works-button.svg";
import {Link} from "react-router-dom";

export default function Menu() {
    return <Fade
        in={true}
        style={{transitionDelay: '1000ms'}}
    ><Grid container spacing={2} css={{marginBottom: 10}}>
        <Grid item md={6} xs={6} css={{textAlign: "center"}}>
            <Link to="submission">
                <Button size="large">
                    <SubmitButtonSvg css={{width: '80%'}} />
                </Button>
            </Link>
        </Grid>
        <Grid item md={6} xs={6} css={{textAlign: "center"}}>
            <Link to="creations">
                <Button size="large">
                    <WorksButtonSvg css={{width: '80%'}} />
                </Button>
            </Link>
        </Grid>
    </Grid>
    </Fade>
}