import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
import {colors} from "./theme";
import {Header} from "./Header";
import {largeDevice} from "./utils";
import React, {useCallback, useState} from "react";

let hideControlsWhenIdle = 20000;
export default function ({photos, onAddLike}) {

    function columns(containerWidth) {
        let columns = 2;
        if (containerWidth >= 500) columns = 2;
        if (containerWidth >= 900) columns = 3;
        if (containerWidth >= 1500) columns = 4;
        return columns;
    }

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [footerStyle, setFooterStyle] = useState(defaultFooterStyle());

    function defaultFooterStyle() {
        return ()=>{
            return base => ({
                ...base,
                background: 'rgba(244, 237, 225,0.8) !important',
                color: '#775c29',
                minHeight: 100,
            })
        }
    }

    function justOpenFooterStyle() {
        return ()=>{
            return base => ({
                ...base,
                background: 'rgba(244, 237, 225,0.8) !important',
                color: '#775c29',
                opacity: '1',
                transform:'translateY(0px)',
                minHeight: 100,
            })
        }
    }

    const openLightBox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
        setFooterStyle(justOpenFooterStyle())
        setTimeout(()=>{
            setFooterStyle(defaultFooterStyle())
        }, hideControlsWhenIdle)
    }, []);

    const closeLightBox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const navButtonStyles = base => ({
        ...base,
        backgroundColor: 'white',
        boxShadow: '0 1px 6px rgba(0, 0, 0, 0.18)',
        color: colors.N60,

        '&:hover, &:active': {
            backgroundColor: 'white',
            color: colors.N100,
            opacity: 1,
        },
        '&:active': {
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.14)',
            transform: 'scale(0.96)',
        },
    });

    if (!photos || photos.length <= 0) {
        return <div></div>
    }

    return <div>
        <Gallery photos={photos} direction="column" columns={columns} onClick={openLightBox} />

        <ModalGateway>
            {viewerIsOpen ? (
                <Modal
                    useBrowserFullscreen={true}
                    allowFullscreen={true}
                    closeOnBackdropClick={false}
                    onClose={closeLightBox}

                    styles={{
                        blanket: base => ({
                            ...base,
                            backgroundColor: colors.N05,
                        }),
                        positioner: base => ({
                            ...base,
                            display: 'block',
                        }),
                    }}
                    currentIndex={currentImage}
                    onAddLike={onAddLike}
                >
                    <Carousel
                        currentIndex={currentImage}
                        components={{ Header: function (props) {
                                return <Header {...props} onAddLike={onAddLike} />
                            }}}
                        hideControlsWhenIdle={hideControlsWhenIdle}
                        frameProps={{ autoSize: 'height' }}
                        views={photos}
                        styles={{
                            container: base => ({
                                ...base,
                                height: '#f4ede1',
                            }),
                            footer: footerStyle,
                            view: base => ({
                                ...base,
                                alignItems: 'center',
                                display: 'flex ',
                                // height: 'calc(100vh - 54px)',
                                height: 'calc(100vh - 130px)',
                                justifyContent: 'center',

                                [largeDevice]: {
                                    padding: 20,
                                },

                                '& > img': {
                                    //maxHeight: 'calc(100vh - 94px)',
                                    maxHeight: 'calc(100vh - 170px)',
                                },
                            }),
                            navigationPrev: navButtonStyles,
                            navigationNext: navButtonStyles,
                        }}
                    />
                </Modal>
            ) : null}

        </ModalGateway>
    </div>
}