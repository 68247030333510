import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Select from "@material-ui/core/Select";
import {jsx} from "@emotion/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import useFetch from "use-http";
import React, {useEffect, useState} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export default ({ randomOptionEnabled, onChange, value, disabled, ...props })=>{
    const [initialRequest, initialResponse] = useFetch(process.env.REACT_APP_API_BASEURL)
    const [classrooms, setClassrooms] = React.useState({});
    const [isOpenError, setIsOpenError] = useState(false)

    useEffect(() => {
        initializeClassroomList()
    }, [])

    async function initializeClassroomList() {
        const initialClassrooms = await initialRequest.get('/classroom')
        if (initialResponse.ok) {
            let classrooms = {}
            initialClassrooms.classrooms.forEach((v) => {
                if (!classrooms[v.type]) {
                    classrooms[v.type] = []
                }

                classrooms[v.type].push(v)
            })
            setClassrooms(classrooms)
        } else {
            setIsOpenError(true)
        }
    }

    return (
        <FormControl {...props} disabled={(disabled || initialRequest.loading) ? true: false}>
            <InputLabel id="classroom-label">班別</InputLabel>
            <Select
                labelId="classroom-label"
                id="classroom-select"
                value={value}
                onChange={onChange}
                label="班別"
            >
                {randomOptionEnabled && <MenuItem value={"random"}>
                    隨機班別
                </MenuItem>}
                {
                    Object.keys(classrooms).map((type) => {
                        return [
                            <ListSubheader value={""} key={type} disableSticky={true}>{type}</ListSubheader>,
                            classrooms[type].map((classroom) => {
                                return <MenuItem value={classroom.id}
                                                 key={classroom.id.toString()}>{classroom.name}</MenuItem>
                            })
                        ]

                    })
                }
            </Select>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={()=>{setIsOpenError(false)}}
                open={isOpenError}
                autoHideDuration={60000}
                message="發生錯誤。"
            >
                <Alert severity="error">
                    發生錯誤。
                </Alert>
            </Snackbar>
        </FormControl>
    )
}