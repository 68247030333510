/** @jsx jsx */
import {jsx} from '@emotion/core';

import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as AddSvg} from "./add.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiFilledInput-root': {backgroundColor:'#c2d6d7'},
        '& .MuiFilledInput-underline:after': {borderBottom:'none'},
        '& .MuiFilledInput-underline:before': {borderBottom:'2px solid rgba(0, 0, 0, 0.42)'},
        '& .MuiFormLabel-root':{fontWeight: 500},
        '& .MuiFormLabel-root.Mui-focused':{color:'rgb(120, 120, 120)', fontWeight: 500}
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#775c29',
        borderRadius: 'calc(20px + 2vmin)',
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const thumb = {
    borderRadius: 2,
    maxWidth: '100%',
    maxHeight: '100%',
    padding: 4,
    boxSizing: 'border-box',
    minHeight: 350,
};

const thumbInner = {
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: '100%',
    height: 'auto'
};

const dropZone = {
    display: 'block',
    width: 'auto',
    height: '100%',
    border: '#c2d6d7 3px dashed',
    borderRadius: 'calc(1vmin)',
    padding: 'calc(1vmin)',
    textAlign: 'center',
    color: '#787878',
};

const dropZoneText = {
    display: 'block',
    padding: 'calc(20vmin) calc(3vmin)',
};

export default function DropZone({onDropped}) {
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps} = useDropzone({
        accept: ['image/jpg', 'image/jpeg'],

        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            onDropped(acceptedFiles[0])
        }
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <section className="container" css={{cursor:'pointer'}}>
            <div {...getRootProps({className: 'dropzone'})} style={dropZone}>
                <input {...getInputProps()} maxfiles={1} multiple={false}/>
                {thumbs.length === 0 && <p style={dropZoneText}><AddSvg css={{width: 80, height: 80, margin: 24}}/><br/>將相片拉入或按這裏上載<br/>(可選擇拍照或掃描上載）</p>}
                <aside>
                    {thumbs}
                </aside>
            </div>
        </section>
    );
}
