/** @jsx jsx */
import {jsx} from '@emotion/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import ExploreIcon from '@material-ui/icons/Explore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import kid1Png from './kid-1.png';
import kid2Png from './kid-2.png';
import kid3Png from './kid-3.png';
import Header from "../../component/Header/Header";
import Footer from "../../component/Footer/Footer";
import Menu from "../../component/Menu/Menu";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#775c29',
        borderRadius: 'calc(20px + 2vmin)',
    },
}));

function Home() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Zoom
                in={true}
                style={{transitionDelay: '500ms'}}
            >
                <Paper className={classes.paper}>
                    <div css={{padding: '0 calc(2px + 2vmin)'}}>
                        <p>
                            各位小公主、小王子，大家好嗎？相信大家都很掛念上學的日子，可以與同學一起讀書玩耍，跟老師和教職員相處。
                        </p>
                        <p>
                            多啦A夢歌中的「爬進奇妙口袋裏，你的希望必得到。」，有想過實踐你的「希望」嗎？今日機會來了！
                        </p>
                        <p>
                            一年一度的 {"<"}家長也敬師{">"} 將於5月舉行，我們開設了名為《奇妙口袋小發明》的畫廊，讓各位小公主小王子表達對香港創價幼稚園教職員的心意，大家動動腦筋，想想為哪一位教職員度身訂造一個奇妙發明，於紙上畫出創作，然後再按「隨意門」上載作品，教職員就會收到那份充滿愛的作品了！
                        </p>
                    </div>
                </Paper>
            </Zoom>
            <Fade in={true} style={{transitionDelay: '500ms'}}>
                <h3 css={{marginBottom: 10}}>例子：</h3>
            </Fade>
            <Fade in={true} style={{transitionDelay: '500ms'}}>
                <Grid container spacing={2} css={{marginBottom: 10}}>
                    <Grid item md={6} xs={12}>
                        <Paper className={classes.paper}>
                            <table border="0" cellSpacing="0">
                                <tbody>
                                <tr>
                                    <th><ExploreIcon
                                        css={{
                                            verticalAlign: 'text-bottom',
                                            fontSize: 'calc(35px + 2vmin)',
                                            paddingRight: 10
                                        }}/>
                                    </th>
                                    <th><span css={{fontWeight: 600}}>觀察</span><br/>
                                        <div css={{fontWeight: 400}}>馮校長經常要站立</div>
                                    </th>
                                </tr>
                                </tbody>
                            </table>
                            <div css={{color: "#72C084"}}>
                                <table border="0" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <th><CheckCircleIcon css={{
                                            verticalAlign: 'text-bottom',
                                            fontSize: 'calc(35px + 2vmin)',
                                            paddingRight: 10
                                        }}/></th>
                                        <th><span css={{fontWeight: 600}}>設計</span><br/>
                                            <div css={{fontWeight: 400}}>設計一個按摩腳機給馮校長</div>
                                        </th>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Paper className={classes.paper}>
                            <div>
                                <table border="0" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <th><ExploreIcon css={{
                                            verticalAlign: 'text-bottom',
                                            fontSize: 'calc(35px + 2vmin)',
                                            paddingRight: 10
                                        }}/></th>
                                        <th><span css={{fontWeight: 600}}>觀察</span><br/>
                                            <div css={{fontWeight: 400}}>基哥哥澆花時太陽很猛</div>
                                        </th>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div css={{color: "#72C084"}}>
                                <table border="0" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <th><CheckCircleIcon css={{
                                            verticalAlign: 'text-bottom',
                                            fontSize: 'calc(35px + 2vmin)',
                                            paddingRight: 10
                                        }}/></th>
                                        <th><span css={{fontWeight: 600}}>設計</span><br/>
                                            <div css={{fontWeight: 400}}>設計一頂冷氣帽給基哥哥</div>
                                        </th>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Fade>
            <Fade in={true} style={{transitionDelay: '500ms'}}>
                <Grid container spacing={2} css={{marginBottom: 10}}>
                    <Grid item lg={4} xs={6}>
                        <div css={{textAlign: "center"}}>
                            <img src={kid1Png} alt="" css={{width: '80%'}}/>
                        </div>
                    </Grid>
                    <Grid item lg={4} xs={6}>
                        <div css={{textAlign: "center"}}>
                            <img src={kid2Png} alt="" css={{width: '80%'}}/>
                        </div>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item lg={4} xs={6}>
                            <div css={{textAlign: "center"}}>
                                <img src={kid3Png} alt="" css={{width: '80%'}}/>
                            </div>
                        </Grid>
                    </Hidden>
                </Grid>
            </Fade>
            <Menu/>
        </React.Fragment>
)
;
}

export default Home;
