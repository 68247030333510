import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './page/Home/Home';
import Submission from './page/Submission/Submission';
import Creations from './page/Creations/Creations';

import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ScrollToTop from "./component/ScrollToTop/ScrollToTop";
import Container from "@material-ui/core/Container";
import Header from "./component/Header/Header";
import Footer from "./component/Footer/Footer";

ReactDOM.render(
    <Router>
        <div className="App">
            <Container className="App-container" maxWidth="lg">
                <Header/>
                <ScrollToTop/>
                <Switch>
                    <Route path="/submission">
                        <Submission/>
                    </Route>
                    <Route path="/creations">
                        <Creations/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>

            </Container>
            <Footer/>
        </div>
    </Router>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
