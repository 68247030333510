/** @jsx jsx */
import {jsx} from '@emotion/core';

import Container from "@material-ui/core/Container";
import footerSchoolPng from "./footer-school.png";
import footerBusPng from "./footer-bus.png";
import footer20yrLogoPng from "./20-years-logo.png";
import React from "react";
import {ReactComponent as CloudSvg} from "./cloud.svg";
import {ReactComponent as HillSvg} from "./hill.svg";

export default function Footer() {
    return <footer css={{position: "relative", height: 300, background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(173,203,213,0.8) 60%, rgba(173,203,213,1) 100%)"}}>
        <div css={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: 300,
        }}>

            <div css={{position: "absolute", left: 0, right: 0,bottom: 40, top: 0}}>
                <div className="bottom-line" css={{
                    position: "absolute",
                    background: '#72C084',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: 24
                }}></div>
                <div className="footer-hill" css={{position: "absolute", bottom: 0, left: 15, height: 45}}>
                    <HillSvg css={{width: 95}}/>
                </div>
                <div css={{position: "absolute", bottom: 0, right: 10}}><img src={footerSchoolPng}/></div>
                <div className="footer-cloud" css={{position: "absolute", bottom: 70, left: 120, height: 45}}>
                    <CloudSvg css={{width: 30, height: 30}}/>
                </div>
                <div className="footer-cloud delay"
                     css={{position: "absolute", bottom: 40, left: 90, height: 45}}>
                    <CloudSvg css={{width: 30, height: 30}}/>
                </div>
                <div css={{position: "absolute", bottom: 0, left: 0, right: 0, top: 0}}>
                    <img src={footer20yrLogoPng} height={130}/>
                </div>
            </div>
            <div className="powered-by" css={{
                position: "absolute",
                //border: 'dashed 2px #ffe100',
                // borderLeft: 'none',
                // borderRight: 'none',
                background:`
                    repeating-linear-gradient(to right,#eee 0,#eee 10px,transparent 10px,transparent 10px) top,
                    repeating-linear-gradient(to right,#eee 0,#eee 15px,transparent 15px,transparent 30px) center,
                repeating-linear-gradient(to right,#eee 0,#eee 10px,transparent 10px,transparent 10px) bottom;
                `,
                backgroundSize:'100% 2px',
                backgroundRepeat:'no-repeat',
                backgroundColor: '#979797',
                left: 0,
                right: 0,
                bottom: 20,
                height: 20
            }}>
                <div className="footer-bus" css={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 45,
                    backgroundImage: "url(" + footerBusPng + ")"
                }}></div>
            </div>
            <div className="powered-by" css={{
                position: "absolute",
                background: '#72C084',
                left: 0,
                right: 0,
                bottom: 0,
                height: 20
            }}><a css={{color: '#fff', fontSize: '12px', textDecoration: 'none'}} href={"http://futureimpactlab.com"} target="_blank">Powered by Future Impact Lab Limited</a></div>
        </div>
    </footer>
}
