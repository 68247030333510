/** @jsx jsx */
import {jsx} from "@emotion/core";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Button from "@material-ui/core/Button";
import submitButtonSvg, {ReactComponent as SubmitButtonSvg} from "../../../assets/submit-button.svg";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import DropZone from "./DropZone/DropZone";
import ClassroomDropdown from "../../../component/ClassroomDropdown/ClassroomDropdown";

import useFetch from "use-http";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFilledInput-root': {backgroundColor: '#c2d6d7'},
        '& .MuiFilledInput-underline:after': {borderBottom: 'none'},
        '& .MuiFilledInput-underline:before': {borderBottom: '2px solid rgba(0, 0, 0, 0.42)'},
        '& .MuiFormLabel-root': {fontWeight: 500},
        '& .MuiFormLabel-root.Mui-focused': {color: 'rgb(120, 120, 120)', fontWeight: 500}
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Form() {
    const classes = useStyles();
    const [classroom, setClassroom] = React.useState("");
    const [file, setFile] = React.useState(null);
    const [studentName, setStudentName] = React.useState("");
    const [caption, setCaption] = React.useState("");
    const [isUploaded, setIsUploaded] = React.useState(false);
    const [isOpenError, setIsOpenError] = useState(false)

    const [request, response] = useFetch(process.env.REACT_APP_API_BASEURL, {cachePolicy: 'no-cache'})
    async function handleSubmit(event) {
        event.preventDefault();
        let data = new FormData();
        data.append('file', file)
        let result = await request.post(`/submission-upload`, data)
        if (!response.ok) {
            setIsOpenError(true)
            return
        }
        let result2 = await request.post(`/submission`, {
            "student_name": studentName,
            "classroom_id": classroom,
            "caption": caption,
            ...result,
        })
        if (!response.ok) {
            setIsOpenError(true)
            return
        }
        setIsUploaded(true)
    }

    if(isUploaded){
        return (
            <Alert iconMapping={{ success: <CheckCircleOutlineIcon fontSize="inherit" /> }}>
                成功提交。
            </Alert>
        )
    }

    return <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
        {isOpenError && <Alert severity="error">
            發生錯誤。
        </Alert>}
        <Grid container>
            <Grid item md={6} xs={12} style={{padding: '8px', paddingBottom: '8px'}}>
                <DropZone onDropped={(file)=> {setFile(file)}}/>
            </Grid>
            <Grid item md={6} xs={12} style={{padding: '0 8px'}}>
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <TextField id="student-name" required className={classes.formControl} label="學生姓名" name="student_name" type="text" variant="filled"  value={studentName} onChange={(event)=>{setStudentName(event.target.value)}}/>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <ClassroomDropdown required variant="filled" className={classes.formControl} onChange={(event)=>{if(event.target.value != "") setClassroom(parseInt(event.target.value))}} value={classroom} />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            required
                            name="caption"
                            className={classes.formControl}
                            id="filled-multiline-static"
                            label="設計概念: (五十字內)"
                            multiline
                            rows={4}
                            variant="filled"
                            value={caption} onChange={(event)=>{setCaption(event.target.value)}}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <div style={{width: '100%', textAlign: 'right'}}>
                            <Button type="submit" disabled={file == null || studentName == "" || caption == "" || classroom == 0 || request.loading} size="small" style={{textAlign: 'right'}}>
                                <SubmitButtonSvg css={{width: '100%'}} />
                            </Button>
                        </div>
                    </Grid>
                    <Grid item md={12} xs={12}><div css={{marginTop: "4px", fontSize: '14px'}}><small css={{color: '#e9582b'}}>注意：重新提交會取代原有作品。</small></div></Grid>
                </Grid>
            </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={request.loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </form>


}