/** @jsx jsx */
import {jsx} from '@emotion/core';

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Zoom from '@material-ui/core/Zoom';
import Form from "./Form/Form";
import {ReactComponent as TitleSvg} from "./submission-titile.svg";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiFilledInput-root': {backgroundColor:'#c2d6d7'},
        '& .MuiFilledInput-underline:after': {borderBottom:'none'},
        '& .MuiFilledInput-underline:before': {borderBottom:'2px solid rgba(0, 0, 0, 0.42)'},
        '& .MuiFormLabel-root':{fontWeight: 500},
        '& .MuiFormLabel-root.Mui-focused':{color:'rgb(120, 120, 120)', fontWeight: 500}
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#775c29',
        borderRadius: 'calc(20px + 2vmin)',
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Submission() {
    const classes = useStyles();

    return (
        <Zoom
            in={true}
            style={{transitionDelay: '500ms'}}
        >
            <div>
                <Paper className={classes.paper}>
                    <Grid container css={{padding: 'calc(2vmin) calc(0.5vmin)'}}>
                        <Grid item md={6} xs={12} css={{padding: 'calc(2vmin) 0'}}>
                            <TitleSvg css={{minWidth: '100px', width: '30%'}}/>
                        </Grid>
                    </Grid>
                    <Form />
                </Paper>

            </div>
        </Zoom>
    );
}

export default Submission;
